<template>
  <div>
    <v-card class="d-flex justify-end mb-6" flat tile>
      <v-breadcrumbs :items="breadcrumbs" large>
        <template v-slot:divider>
          <v-icon>{{ icons.mdiSlashForward }} </v-icon>
        </template>
      </v-breadcrumbs>
    </v-card>
    <v-card flat class="pa-3 mt-2">
      <!-- Modal -->
      <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">JENIS USAHA</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <v-text-field
                class="pt-5"
                label="Search"
                @keyup="doSearch()"
                v-model="search"
                dense
                outlined
              ></v-text-field>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-uppercase">NO</th>
                      <th class="text-center text-uppercase">NAMA JENIS USAHA</th>

                      <th class="text-center text-uppercase">ACTION</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in displayData()" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td class="text-center">{{ data.nama_jenisusaha }}</td>

                      <td class="text-center">
                        <!-- <button
                        data-dismiss="modal"
                        aria-label="Close"
                        class="btn btn-primary btn-sm"
                        v-on:click="pilihJenisUsaha(r.id_role, r.nama_role)"
                      >
                        Pilih
                      </button> -->

                        <v-btn
                          data-bs-dismiss="modal"
                          color="primary"
                          v-on:click="pilihJenisUsaha(data.id_jenisusaha, data.nama_jenisusaha)"
                          class="me-3 mt-4"
                        >
                          <v-icon color="#ECEFF1">
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="6">
                        <center>
                          <div class="v-data-footer">
                            <!-- <div class="v-data-footer__pagination" bis_skin_checked="1">1-10 of 100</div> -->
                            <span class="v-data-footer__icons-before" bis_skin_checked="1">
                              <button
                                type="button"
                                @click="page--"
                                :class="[
                                  'v-btn  v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page == 1 ? 'v-btn--disabled' : '',
                                ]"
                                aria-label="Previous page"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                            <span
                              :class="['v-data-footer', pageNumber == page ? 'active' : '']"
                              v-for="pageNumber in pages.slice(page - 1, page + 5)"
                              :key="pageNumber"
                              @click="page = pageNumber"
                            >
                              <!-- <a href="#">{{ pageNumber }}</a> -->
                              <button
                                type="button"
                                class="v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default"
                                aria-label="Next page"
                              >
                                {{ pageNumber }}
                              </button>
                            </span>
                            <span class="v-data-footer__icons-after" bis_skin_checked="1">
                              <button
                                type="button"
                                :class="[
                                  'v-btn v-btn--icon v-btn--round v-btn--text theme--light v-size--default',
                                  page < pages.length ? '' : 'v-btn--disabled',
                                ]"
                                aria-label="Next page"
                                @click="page++"
                              >
                                <span class="v-btn__content"
                                  ><span aria-hidden="true" class="v-icon notranslate theme--light"
                                    ><svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      role="img"
                                      aria-hidden="true"
                                      class="v-icon__svg"
                                    >
                                      <path
                                        d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z"
                                      ></path></svg></span
                                ></span>
                              </button>
                            </span>
                          </div>
                        </center>
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
            <!-- <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> -->
          </div>
        </div>
      </div>
      <!-- end Modal -->
      <v-card-text>
        <v-card-title class="text-no-wrap pt-1 ps-2"> CREATE KATEGORI PRODUK</v-card-title>
        <form @submit.prevent="CreateData" ref="form">
          <v-row>
            <v-col cols="12" v-if="errors.length">
              <v-alert color="error" v-for="error in errors" :key="error" text class="mb-0">
                <div class="d-flex align-start">
                  <v-icon color="warning"> </v-icon>
                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">{{ error }}</p>
                  </div>
                </div>
              </v-alert>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field
                label="Nama Kategori Produk"
                v-model="kategoriproduks.nama_kategoriproduk"
                dense
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="Nama Jenis Usaha"
                v-model="kategoriproduks.nama_jenisusaha"
                id="nama_jenisusaha"
                dense
                outlined
                readonly
              ></v-text-field>

              <text
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                label="ID Jenis Usaha"
                v-model="kategoriproduks.id_jenisusaha"
                id="id_jenisusaha"
                dense
                outlined
                readonly
                type="hidden"
              ></text>
            </v-col>
            <v-col md="6" cols="12">
              <v-card-text class="text-no-wrap pt-1 ps-1"> Gambar Produk </v-card-text>
              <div class="cropper-area">
                <div class="img-cropper">
                  <vue-cropper ref="cropper" :aspect-ratio="2 / 2" :src="showPhoto()" preview=".preview" />
                </div>
              </div>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="showFileChooser">
                Choose Image
              </v-btn>
              <v-btn type="submit" color="primary" class="me-3 mt-4" @click.prevent="cropImage"> Crop </v-btn>
              <input
                ref="input"
                style="display: none"
                type="file"
                :name="kategoriproduks.icon_kategoriproduk"
                accept="image/*"
                @change="setImage"
              />
              <br />
              <br />
              <v-card-text class="text-no-wrap pt-1 ps-1"> Crop Gambar Produk </v-card-text>
              <section class="preview-area">
                <div class="cropped-image">
                  <img
                    :src="
                      kategoriproduks.icon_kategoriproduk == '' ? defautlPhoto() : kategoriproduks.icon_kategoriproduk
                    "
                    alt="Cropped Image"
                  />
                </div>
              </section>
            </v-col>

            <v-col cols="12">
              <v-btn type="submit" color="primary" class="me-3 mt-4"> Save </v-btn>

              <router-link :to="{ name: 'kategori-produks-index' }">
                <v-btn color="secondary" outlined class="mt-4" type="reset"> Cancel </v-btn>
              </router-link>
            </v-col>
          </v-row>
        </form>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mdiCheckBold, mdiSlashForward } from '@mdi/js'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          href: '/admin/dashboard',
        },
        {
          text: 'Kategori Produk',
          disabled: false,
          href: '/admin/kategori-produks',
        },
        {
          text: 'Create Kategori Produk',
          disabled: true,
        },
      ],

      icons: {
        mdiCheckBold,
        mdiSlashForward,
      },

      kategoriproduks: {
        nama_kategoriproduk: '',
        icon_kategoriproduk: '',
        id_jenisusaha: '',
      },
      errors: [],
      jenisusaha: [],

      page: 1,
      perPage: 10,
      pages: [],
      search: '',
      searchResult: [],
    }
  },
  mounted() {
    this.$isLoading(true)
    setTimeout(() => {
      this.getJenisUsaha()
      this.$isLoading(false)
    }, 2000)
  },
  methods: {
    getJenisUsaha() {
      //state token
      localStorage.getItem('token')

      let url = process.env.VUE_APP_ROOT_API + '/api/admin/jenisusahas'
      return this.axios.get(url).then(response => {
        this.jenisusaha = response.data.jenisusahas
      })
    },
    defautlPhoto() {
      const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/menu/produk.png`

      return imageDefault
    },

    showPhoto() {
      if (this.kategoriproduks.icon_kategoriproduk.length == 0) {
        const imageDefault = `${process.env.VUE_APP_ROOT_API}/images/menu/produk.png`

        return imageDefault
      }
      const imageClient = `${process.env.VUE_APP_ROOT_API}/${this.kategoriproduks.icon_kategoriproduk}`

      return imageClient
    },
    setImage(e) {
      const file = e.target.files[0]
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file')

        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = event => {
          this.kategoriproduks.icon_kategoriproduk = event.target.result

          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.kategoriproduks.icon_kategoriproduk = this.$refs.cropper.getCroppedCanvas().toDataURL()
    },
    showFileChooser() {
      this.$refs.input.click()
    },
    CreateData(e) {
      // console.log('ID_ROLE: ' + this.users.id_role)

      if (this.$data.kategoriproduks.nama_kategoriproduk.length === 0) {
        // this.errors.push('Email harus diisi !')
        this.$swal.fire({
          title: 'Error',
          text: 'Nama kategori produk wajib diisi !',
          icon: 'error',
          showCancelButton: false,
          showConfirmButton: false,
          timer: 1000,
        })
      } else {
        let uri = process.env.VUE_APP_ROOT_API + '/api/admin/kategoriproduks/store'

        this.axios
          .post(uri, this.kategoriproduks)
          .then(response => {
            this.$swal.fire({
              title: 'Success',
              text: 'Karegori produk created successfully',
              icon: 'success',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 1000,
            })

            // this.$router.push({ name: 'users-index' })
          })
          .catch(e => {
            this.$swal.fire({
              title: 'Error!',
              html: this.kategoriproduks.nama_kategoriproduk + ' sudah digunakan !',
              icon: 'error',
              showCancelButton: false,
              showConfirmButton: false,
              timer: 2000,
            })
          })

        e.preventDefault()
        setTimeout(() => {
          this.$router.push({ name: 'kategoriproduks-index' })
        }, 3000)
      }
    },

    doSearch() {
      let go = this.jenisusaha.filter(item => {
        return this.search
          .toLowerCase()
          .split(' ')
          .every(v => item.nama_jenisusaha.toLowerCase().includes(v))
      })
      this.searchResult = go
      this.pages = []
      this.setPages()
    },
    displayData() {
      let paginate

      if (this.search == '') {
        paginate = this.paginate(this.jenisusaha)
      } else {
        paginate = this.paginate(this.searchResult)
      }

      return paginate
    },
    setPages() {
      let numberOfPages

      if (this.search == '') {
        numberOfPages = Math.ceil(this.jenisusaha.length / this.perPage)
      } else {
        numberOfPages = Math.ceil(this.searchResult.length / this.perPage)
      }
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index)
      }
    },
    paginate(jenisusaha) {
      let page = this.page
      let perPage = this.perPage
      let from = page * perPage - perPage
      let to = page * perPage
      return jenisusaha.slice(from, to)
    },

    pilihJenisUsaha(id_jenisusaha, nama_jenisusaha) {
      document.querySelector('#id_jenisusaha').setAttribute('value', id_jenisusaha)
      document.querySelector('#nama_jenisusaha').setAttribute('value', nama_jenisusaha)
      this.kategoriproduks.id_jenisusaha = id_jenisusaha
      this.kategoriproduks.nama_jenisusaha = nama_jenisusaha
    },
  },
}
</script>
